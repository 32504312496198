<template>
  <div
    :class="[{'border-b border-x border-neutral-200' : faq }]"
  >
    <UiCollapsed>
      <template #head>
        <template v-if="$slots.toggle">
          <div
            :class="[
              {'flex items-stretch justify-start text-base cursor-pointer w-full' : faq },
              {'flex justify-between items-center text-base cursor-pointer' : normal }
            ]"
          >
            <div
              v-if="$slots.number"
              :class="[
                {'flex items-center justify-center flex-none w-[50px] text-center border-r border-neutral-200 text-3xl font-bold text-neutral-200' : faq },
                {'w-auto' : normal}
              ]"
            >
              <slot name="number" />
            </div>
            <div
              :class="[
                {'flex items-center flex-1 text-left px-5 py-3' : faq },
                {'w-auto text-left' : normal}
              ]"
            >
              <slot name="toggle" />
            </div>
            <div
              :class="[
                {'flex-none flex items-center m-5' : faq },
                {'m-5' : normal}
              ]"
            >
              <UiIcon src="/svg/icons/arrow-thin.svg" width="16" height="16" alt="show more" class="fill-petrol-500 accordion-arrow" />
            </div>
          </div>
        </template>
      </template>
      <template #collapse>
        <div class="flex">
          <div v-if="faq" class="flex-none w-[50px] border-r border-neutral-200" />
          <div
            :class="[{'px-5 pb-5 w-full' :faq }]"
          >
            <slot />
          </div>
        </div>
      </template>
    </UiCollapsed>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  }
})

const normal = computed(() => props.design === 'normal')
const faq = computed(() => props.design === 'faq')
</script>
<style lang="scss">
.accordion-arrow{
  @apply rotate-90;
  z-index: -1;
}
.active{
  .accordion-arrow{
    @apply rotate-270
  }
}
</style>
